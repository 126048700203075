import React from 'react'
import MarkdownEditorLite from '../components/MarkDownEditorLite'

function EditMarkDown() {
  return (
    <div className='content-section'>
        {/* <h3>EditMarkDown</h3> */}
        {<MarkdownEditorLite/>}
    </div>
  )
}

export default EditMarkDown