import React from 'react'
// import ReadMarkdown from '../components/ReadMarkdown'
import ReadMarkdown2 from '../components/ReadMarkdown2'

const HomePage = () => {
  return (
    <div className='content-section'>
        {<ReadMarkdown2/>}
    </div>
  )
}

export default HomePage