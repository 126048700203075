import React,{useState,useEffect} from 'react'
import { Button, Header, Icon, Modal,Form } from 'semantic-ui-react'
// import { updateData } from '../api/api'
// import { updateData } from '../../api/api'
// import FormError from "../../forms/FormError"
// import FormSuccess from '../../forms/FormSuccess'
import FormError from '../forms/FormError'
import FormSuccess from '../forms/FormSuccess'




const SaveModal = ({sendFunction,dataToSend}) => {


    
    const [open, setOpen] = useState(false)
    const[form,setForm] = useState({})
    const[error,setError] = useState(null)
    const [data,setData] = useState(null)
    const [loading,setLoading] = useState(false)
    // const[error,setError] = useState(null)
    // const data = useState({})
    // const loading = useState(false)
    // const errorMessage = useState(null)

    useEffect(()=>{
                if(data){
            setOpen(false)
            setLoading(false)
        }
    },[data])

    // let fileNameConstructed = `Exam records at${wassignedBy} for ${Month},${year}_${timeStamp}`

    // const handleCreate=(e)=>{
    //     e.preventDefault()
        
    //     // exportToCSV(data, fileNameConstructed)
    //     setOpen(false)
    // }

    async function handleCreate(e) {
        e.preventDefault();
        // console.log(form);
        setLoading(true)
        UpdateSetting({commit_message:form.commit_message},setData,setError)
        setLoading(false)
        
    }

    const UpdateSetting = (userData,dataFunc,errorFunc) =>{
        setLoading(true);
    // console.log(userData)
        // updateData(userData,dataFunc,errorFunc)
        // console.log(form?.commit_message)
        sendFunction(form?.commit_message,dataFunc,errorFunc)
    }

    function onchange(e) {
        setForm((form) => {
            return {
                ...form,
            [e.target.name]: e.target.value
            };
        });
    }
    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={<Button color="green" button><Icon name='plus' />Save changes</Button>}
            >
            <Modal.Header>Set receiver email</Modal.Header>
            <Modal.Content image>
                <Icon name="save" size="massive" color="blue"/>
                
                <Modal.Description>
                    <Header>Add commit message</Header>
                    <Form success warning > 
                    
                    {error?FormError(error):""}
                    {data?FormSuccess(data):""}
                    <Form.Field>
                        <label>commit message</label>
                        <input type="text" name='commit_message' placeholder='added clause blah blah blah' value={form.name} onChange={onchange}/>
                    </Form.Field>
                    {/* <Form.Field>
                        <label>Exam unit code</label>
                        <input type='tmap(([item, index]) 
                        <label>Exam date</label>
                        <input type='date' name='date' placeholder='Exam date' value={form.date} onChange={onchange}/>
                    </Form.Field>
                    <Form.Field>
                        <label>Start time</label>
                        <input type='time' name='start' placeholder='Exam start time' value={form.start} onChange={onchange}/>
                    </Form.Field>
                    <Form.Field>
                        <label>End time</label>
                        <input type='time' name='end' placeholder='Exam end time' value={form.end} onChange={onchange}/>
                    </Form.Field> */}
                    {/* <Button  loading={loading} fluid primary type='submit'><Icon name="plus" size="small"/>Add person</Button> */}
            </Form>
                    
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button color='red' onClick={() => setOpen(false)}>
                <Icon name='remove' /> Cancel
                </Button>
                <Button color='green' loading={loading} onClick={handleCreate}>
                <Icon name='plus square' /> Save 
                </Button>
            </Modal.Actions>
    </Modal>
    )
}

export default SaveModal