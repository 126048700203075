import React from 'react'
import ReadMarkdown2 from '../components/ReadMarkdown2'

function ViewMarkdown() {
  return (
    <div className='content-section'>
        {<ReadMarkdown2/>}
    </div>
  )
}

export default ViewMarkdown